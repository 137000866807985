import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useGlobalStateContext } from "../../common/Context/app";
import Layout from '../../common/components/template/Layout';
import { PageProp, SimpleContentGridT } from '../../common/types';
import PagePartRenderer from '../PagePartRender'

const Index: React.FC<PageProp> = ({ data, pageContext }) => {
  const { setSiteConfig } = useGlobalStateContext();
  const pageParts = data?.pageData?.pageParts;

  if(pageContext?.events) {
    const simpleContentGrid = pageParts?.find((item) => item._type === 'simpleContentGrid') as SimpleContentGridT;
    if(simpleContentGrid) {
      simpleContentGrid.events = pageContext?.events;
    }
  }

  useEffect(() => {
    setSiteConfig && pageContext && setSiteConfig({
      payload: pageContext,
      type: 'SET'
    })
  }, [pageContext]);

  return (
    <Layout
    metaDescription={pageContext?.metadata?.page_description || ''} 
    metaTitle={pageContext?.metadata?.page_title || ''} 
    meta={[]} 
    lang={'en'}
     >
      {pageParts?.map?.((part, i) => <PagePartRenderer part={ part } key={i} id={i}  />)}
    </Layout>
  )
}

export const query = graphql`
  query EventPage($id: String!) {
    pageData: sanityEventPage(_id: {eq: $id}) {
      pageParts {
        ... on SanityEventGalleryRef {
          _key
          _type
          navanchor
          title
          gallery: galleryReference {
            pageParts {
              ... on SanityPictureGallery {
                _key
                _type
                pictures: _rawPictures(resolveReferences: {maxDepth: 10})
                navanchor
                display
                zoom
              }
            }
          }
        }

        ... on SanitySimpleContentGrid {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 5})
          button:_rawButton(resolveReferences: {maxDepth: 5})
          contentBlocks: _rawContentBlocks(resolveReferences: {maxDepth: 5})
          footerImage: _rawFooterImage(resolveReferences: {maxDepth: 5})
          headerImage:_rawHeaderImage(resolveReferences: {maxDepth: 5})
          partStyle
          navanchor
          title
        }

        ... on SanityCardWide {
          _key
          _type
          navanchor
          title
          image: _rawImage(resolveReferences: {maxDepth: 8})
          body: _rawBody(resolveReferences: {maxDepth: 3})
          cardStyle
          cardHeading
          button:_rawButton(resolveReferences: {maxDepth: 5})
        }

        ... on SanityEventInEvent {
          _key
          _type
          eventBody:_rawEventInEventBody(resolveReferences: {maxDepth: 5})
          eventIcon: _rawEventInEventIcon(resolveReferences: {maxDepth: 5})
          eventImage:_rawEventInEventImage(resolveReferences: {maxDepth: 5})
          navanchor
          title
        }

        ... on SanityHero {
          _key
          _type
          image: _rawImage(resolveReferences: {maxDepth: 10})
          navanchor
          partStyle
          title
        }

        ... on SanitySimpleContentBlock {
          _key
          _type
          body: _rawBody(resolveReferences: {maxDepth: 5})
          button:_rawButton(resolveReferences: {maxDepth: 5})
          image: _rawImage(resolveReferences: {maxDepth: 5})
          image_placement
          navanchor
          partStyle
          title
        }

        ... on SanityVendorDirectory {
          _key
          _type
          navanchor
          title
        }

        ... on SanityUpcomingEvents {
          _type
          navanchor
          skipNextEvent
        }

        ... on SanityNotification {
          _key
          _type
          alertLevel
          body: _rawBody(resolveReferences: {maxDepth: 5})
        }

      }
    }
  }
`

export default Index;
